import { NgModule } from '@angular/core';
import { NgxWheelComponent } from './ngx-wheel.component';



@NgModule({
  declarations: [NgxWheelComponent],
  imports: [
  ],
  exports: [NgxWheelComponent]
})
export class NgxWheelModule { }
