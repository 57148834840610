import QRCodeStyling from 'qr-code-styling'
import { AsyncSubject } from 'rxjs';

import { Templates } from './ngx-qrcode-styling.templates'
import { Options } from './ngx-qrcode-styling.options'

/**
 * HAS_OWN_PROPERTY
 * Fix issue vs ng v 6-7-8
 * Optional chaining (?.) just have on ng v 9++
 * eg: HAS_OWN_PROPERTY(config, 'frameOptions.style') // output: boolean
 * @param obj 
 * @param propertyPath 
 * @returns 
 */
const HAS_OWN_PROPERTY = (obj: any, propertyPath: string) => {
    const properties = propertyPath.split(".");
    for (let  i = 0; i < properties.length; i++) {
        let prop = properties[i];
        if (!obj.hasOwnProperty(prop)) {
            return false;
        } else {
            obj = obj[prop];
        }
    }
    return true;
};

/**
 * drawQrcode
 * @param config 
 * @param container 
 * @returns 
 */
export function drawQrcode(config: Options, container: HTMLElement | HTMLVideoElement | HTMLCanvasElement | SVGElement | any): AsyncSubject<any> {
    
    // Reject
    if (!config || !container) return;

    const subject = new AsyncSubject();
    const element = document.createElement("div");
    
    /**
     * QRCODE_NONE_FRAME
     * @returns 
     */
    const QRCODE_NONE_FRAME = () => {
        if (HAS_OWN_PROPERTY(config, 'frameOptions')) {
            return false;
        } else {
            const encodeConfig = () => {
                let deep = config && JSON.parse(JSON.stringify(config)); // deep
                return Object.assign({data: (window as any).unescape(encodeURIComponent(deep && deep.data || ''))}, deep);
            }
            // removeChild
            while (container.firstChild) {
                container.removeChild(container.lastChild);
            }
            const CR = new QRCodeStyling(encodeConfig() as Options);
            // append to container
            CR.append(container);
            return true;
        }
    }

    const styleName = HAS_OWN_PROPERTY(config, 'frameOptions.style') ? config.frameOptions.style : 'F_020';
    const height = HAS_OWN_PROPERTY(config, 'frameOptions.height') ? config.frameOptions.height : 300;
    const width = HAS_OWN_PROPERTY(config, 'frameOptions.width') ? config.frameOptions.width : 300;
    const x = HAS_OWN_PROPERTY(config, 'frameOptions.x') ? config.frameOptions.x : 50;
    const y = HAS_OWN_PROPERTY(config, 'frameOptions.y') ? config.frameOptions.y : 50;

    /**
     * ADD_FRAME_SVG_TO_ELEMENT
     * @returns
     */
    const ADD_FRAME_SVG_TO_ELEMENT = () => {
        const http = fetch(`https://raw.githubusercontent.com/id1945/ngx-qrcode-styling/master/svg/${styleName}.svg`, { method: 'GET' })
        return new Promise((resolve, reject) => {
            http.then(response => response.text()).then(result => {
                if (result !== "404: Not Found") {
                    upgradeSvg(result);
                }
                resolve(result);
            }).catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }

    const upgradeSvg = (result: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(result, "image/svg+xml");
        if (doc) {
            const svgEl = (doc.documentElement.children as any)[styleName + '_svg'];
            if (!svgEl) return;
            const textEls = svgEl.getElementsByClassName("frame-text");
            const contentEls = svgEl.getElementsByClassName("frame-content");
            const containerEls = svgEl.getElementsByClassName("frame-container");
            const updateStyle = (el: any, config: any) => {
                if (el) {
                    for (const key in config) {
                        if ((['x', 'y', 'transform'] as any).includes(key)) {
                            el.setAttribute(key, config && config[key]);
                        } else if ((['textContent'] as any).includes(key)) {
                            el[key] = config && config[key];
                        } else {
                            el.style[key] = config && config[key];
                        }
                    }
                }
            }

            const createElementNS = (config: any) => {
                const svgNS = "http://www.w3.org/2000/svg";
                const newText = document.createElementNS(svgNS, "text");
                updateStyle(newText, config);
                svgEl.appendChild(newText);
            }

            if (HAS_OWN_PROPERTY(config, 'frameOptions.texts')) {
                Object.assign([], config.frameOptions.texts).forEach((text: any, i: any) => {
                    const el = Object.assign([], textEls)[i];
                    el ? updateStyle(el, text) : createElementNS(text);
                });
            }

            if (HAS_OWN_PROPERTY(config, 'frameOptions.containers')) {
                Object.assign([], containerEls).forEach((el: any, i: any) => {
                    updateStyle(el, config.frameOptions.containers[i]);
                });
            }

            if (HAS_OWN_PROPERTY(config, 'frameOptions.contents')) {
                Object.assign([], contentEls).forEach((el: any, i: any) => {
                    updateStyle(el, config.frameOptions.contents[i]);
                });
            }

            element.appendChild(doc.documentElement);
        }
    }

    /**
     * UPDATE_POSITION_QRCODE_ON_FRAME
     * @returns HTMLElement
     */
    const UPDATE_POSITION_QRCODE_ON_FRAME = () => {
        const addsvg = element.querySelector('.addsvg');
        addsvg && addsvg.setAttribute("transform", `translate(${x},${y})`);
        return addsvg as HTMLElement;
    }

    /**
     * UPDATE_ROTATE_SCALE_QRCODE_ON_FRAME
     * @param svg
     * @returns void
     */
    const UPDATE_ROTATE_SCALE_QRCODE_ON_FRAME = (svg: HTMLElement) => {
        if (HAS_OWN_PROPERTY(config, 'rotate') && svg) {
            Object.assign([], svg.childNodes[0].childNodes).forEach((node: any) => {
                if (node.nodeName === 'rect') {
                    node.style.transformOrigin = `50% 50%`;
                    node.style.transform = `rotate(${config && config.rotate || 0}deg)`;
                }
            });
        }
        if (HAS_OWN_PROPERTY(config, 'scale') && svg) {
            Object.assign([], svg.childNodes[0].childNodes).forEach((node: any) => {
                if (node.nodeName === 'rect') {
                    node.style.scale = config && config.scale || 0;
                }
            });
        }
    }

    /**
     * CREATE_QRCODE_INTO_FRAME
     * @param addsvg 
     * @returns 
     */
    const CREATE_QRCODE_INTO_FRAME = (addsvg: HTMLElement) => {
        const defaultConfig = () => {
            let deep = config && JSON.parse(JSON.stringify(config)); // deep
            deep = Object.assign({}, deep, { type: 'svg', data: (window as any).unescape(encodeURIComponent(deep && deep.data || '')) });
            delete deep.frameOptions;
            delete deep.template;
            return deep;
        }
        // removeChild
        while (container.firstChild) {
            container.removeChild(container.lastChild);
        }
        const CR = new QRCodeStyling(defaultConfig() as Options);
        return HAS_OWN_PROPERTY(CR, '_svgDrawingPromise') && CR._svgDrawingPromise.then(() => {
            CR.append(addsvg);
        }).catch((error: any) => console.error(error))
    }

    /**
     * QRCODE_TYPE_SVG
     * @returns 
     */
    const QRCODE_TYPE_SVG = () => {
        if (config && config.type === 'svg') {
            UPDATE_SIZE_SVG();
            container.appendChild(element);
            return true;
        }
        return false;
    }

    /**
     * CREATE_CANVAS_WITH_SIZE
     * @returns 
     */
    const CREATE_CANVAS_WITH_SIZE = () => {
        const canvas = document.createElement('canvas');
        canvas.height = height;
        canvas.width = width;
        container.appendChild(canvas);
        return canvas;
    }

    /**
     * ELEMENT_CONVERT_TO_BASE64
     * @param s1 
     * @returns 
     */
    const ELEMENT_CONVERT_TO_BASE64 = (s1: HTMLElement) => {
        let b64 = "data:image/svg+xml;base64,";
        const xml = s1 && new XMLSerializer().serializeToString(s1);
        return b64 += xml && btoa(unescape(encodeURIComponent(xml)));
    }

    /**
     * UPDATE_SIZE_SVG
     * @returns 
     */
    const UPDATE_SIZE_SVG = () => {
        const s1 = element.querySelector(`#${styleName}_svg`);
        s1 && s1.setAttribute('height', `${height}px`);
        s1 && s1.setAttribute('width', `${width}px`);
        return s1 as HTMLElement;
    }

    /**
     * BASE64_TO_BLOB
     * @param base64Image 
     * @returns 
     */
    const BASE64_TO_BLOB = (base64Image: string) => {
        // Split into two parts
        const parts = base64Image.split(";base64,");
        // Hold the content type
        const imageType = parts[0].split(":")[1];
        // Decode Base64 string
        const decodedData = window.atob(parts[1]);
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

    /**
     * CREATE_IMAGE
     */
    const CREATE_IMAGE = () => {
        const img = new Image();
        const ctx = CREATE_CANVAS_WITH_SIZE().getContext("2d");
        img.onload = function () {
            ctx && ctx.drawImage(img, 0, 0);
        };
        const blob = BASE64_TO_BLOB(ELEMENT_CONVERT_TO_BASE64(UPDATE_SIZE_SVG()));
        const blobUrl = URL.createObjectURL(blob);
        img.src = blobUrl;
    }

    /**
     * MAIN
     */
    (async function () {
        if (QRCODE_NONE_FRAME()) {
            subject.next({ config, container });
            subject.complete();
            return // Mode qrcode basic
        } else {
            await ADD_FRAME_SVG_TO_ELEMENT();
            const ADDSVG = UPDATE_POSITION_QRCODE_ON_FRAME();
            await CREATE_QRCODE_INTO_FRAME(ADDSVG);
            UPDATE_ROTATE_SCALE_QRCODE_ON_FRAME(ADDSVG);
            if (QRCODE_TYPE_SVG()) {
                // Mode qrcode + frame type svg
                subject.next({ config, container });
                subject.complete();
            } else {
                // Mode qrcode + frame type canvas
                CREATE_IMAGE();
                subject.next({ config, container });
                subject.complete();
            }
        }
    })();

    return subject;
}


/**
 * defaultTemplate
 * @param config 
 * @returns 
 */
export const defaultTemplate = (config?: Options): Options => {
    let deep = config && JSON.parse(JSON.stringify(config));
    return HAS_OWN_PROPERTY(config, 'template') ? Object.assign({}, Templates(config.template.toLocaleLowerCase()), deep) : deep;
};

/**
 * deepUpdate
 * @param config 
 * @param configUpdate 
 * @returns 
 */
export const deepUpdate = async (config: Options, configUpdate: Options): Promise<Options> => {
    const origin = config && JSON.parse(JSON.stringify(config));
    let clone = Object.assign({}, origin, configUpdate);
    const keys = ['frameOptions', 'qrOptions', 'imageOptions', 'dotsOptions', 'cornersSquareOptions', 'cornersDotOptions', 'backgroundOptions'];
    for await (const key of keys) {
        if (HAS_OWN_PROPERTY(configUpdate, key)) {
            const update = { 
                [key]: Object.assign({}, origin && origin[key], (configUpdate as any)[key])
            };
            clone = Object.assign({}, clone, update);
        }
    }
    return clone;
};
